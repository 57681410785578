$screen-s: 900px;
$cs-white: #fff;
$cs-black: #000;
$cs-grey-04: #a8a8a7;
$cs-grey-05: #7c7c7b;
$w-full: 100%;
$w-flex: 80%;
$size-s: 13px;
$size-m: 16px;
$size-l: 36px;

.user-footer {
    width: $w-full;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 85px 0 0 0;

    & > .main-footer {
        width: $w-full;
        background-color: $cs-black;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 40px 0;
        box-sizing: border-box;
        font-size: 14px;
        text-align: left;

        & > .main-content {
            width: $w-flex;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            color: $cs-white;

            & > .disclaimer {
                width: $w-full;

                & > .title {
                    font-weight: 700;
                }

                & > .content {
                    margin: 12px 0 0 0;
                }
            }

            & > .divider {
                margin: 18px 0 0 0;
                height: 1px;
                width: $w-full;
                background-color: $cs-grey-04;
            }

            & > .important-note {
                margin: 37px 0 0 0;
                width: $w-full;
                display: flex;
                justify-content: space-between;
                align-items: center;
                color: $cs-grey-04;

                @media only screen and (max-width: $screen-s) {
                    flex-direction: column-reverse;
                    align-items: flex-end;
                }

                & > .content {
                    max-width: 75%;
                    @media only screen and (max-width: $screen-s) {
                        max-width: 100%;
                        margin: 34px 0 0 0;
                    }
                }

                & > .logo {
                    width: 165px;
                    & > img {
                        width: 165px;
                    }
                }
            }
        }
    }

    & > .post-footer {
        width: $w-full;
        background-color: $cs-white;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 20px 0;
        box-sizing: border-box;
        text-align: left;
        font-size: 11px;

        & > .main-content {
            width: $w-flex;
            display: flex;
            justify-content: space-between;
            align-items: center;

            @media only screen and (max-width: $screen-s) {
                flex-direction: column;
                text-align: left;
            }

            & > .copyright {
                // width: $w-full;
            }
            & > .navigation {
                display: flex;
                justify-content: flex-end;
                align-items: center;
                flex-wrap: wrap;

                @media only screen and (max-width: $screen-s) {
                    margin: 10px 0 0 0;
                    justify-content: flex-start;
                }
                
                & > .item {
                    margin: 0 5px 0 0;
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    line-height: 1.5em;

                    & > .label {
                        &:hover {
                            cursor: pointer;
                            text-decoration: underline;
                        }
                    }

                    &>.divider{
                        margin: 0 10px;
                    }
                }
            }
        }
    }
}
