@font-face {
  font-family: "微软雅黑";
  src: local("微软雅黑"), url(./fonts/MicrosoftYahei/Yahei.ttf) format("truetype");
}

@font-face {
  font-family: "Microsoft Yahei";
  src: local("Microsoft Yahei"), url(./fonts/MicrosoftYahei/Yahei_en.ttf) format("truetype");
}

@font-face {
  font-family: "STHeiti";
  src: local("STHeiti"), url(./fonts/stheiti/stheiti.ttf) format("truetype");
}

@font-face {
  font-family: "华文黑体";
  src: local("华文黑体"), url(./fonts/stheiti/stheiti.ttf) format("truetype");
}

@font-face {
  font-family: "CS-Headline";
  src: local("CS-Headline"), url(./fonts/CreditSuisseHeadlinePan/CreditSuisseHeadlineWeb-Regular.woff) format("woff");
}

@font-face {
  font-family: "CS-EP";
  src: local("CS-EP"), url(./fonts/CS_Type_EP_ET/CSW07eP-Boldv4.woff) format("woff");
  font-weight: bold;
}
@font-face {
  font-family: "CS-EP";
  src: local("CS-EP"), url(./fonts/CS_Type_EP_ET/CSW07eP-LightItalicv4.woff) format("woff");
  font-weight: lighter;
  font-style: italic;
}
@font-face {
  font-family: "CS-EP";
  src: local("CS-EP"), url(./fonts/CS_Type_EP_ET/CSW07eP-Lightv4.woff) format("woff");
  font-weight: lighter;
}
@font-face {
  font-family: "CS-EP";
  src: local("CS-EP"), url(./fonts/CS_Type_EP_ET/CSW07eP-RomanItalicv4.woff) format("woff");
  font-style: italic;
}
@font-face {
  font-family: "CS-EP";
  src: local("CS-EP"), url(./fonts/CS_Type_EP_ET/CSW07eP-Romanv4.woff) format("woff");
}
@font-face {
  font-family: "CS-ET";
  src: local("CS-ET"), url(./fonts/CS_Type_EP_ET/CSW07eT-Boldv4.woff) format("woff");
  font-weight: bold;
}
@font-face {
  font-family: "CS-ET";
  src: local("CS-ET"), url(./fonts/CS_Type_EP_ET/CSW07eT-LightItalicv4.woff) format("woff");
  font-weight: lighter;
  font-style: italic;
}
@font-face {
  font-family: "CS-ET";
  src: local("CS-ET"), url(./fonts/CS_Type_EP_ET/CSW07eT-Lightv4.woff) format("woff");
  font-weight: lighter;
}
@font-face {
  font-family: "CS-ET";
  src: local("CS-ET"), url(./fonts/CS_Type_EP_ET/CSW07eT-RomanItalicv4.woff) format("woff");
  font-style: italic;
}
@font-face {
  font-family: "CS-ET";
  src: local("CS-ET"), url(./fonts/CS_Type_EP_ET/CSW07eT-Romanv4.woff) format("woff");
}

.font-zh {
  font-family: "CS-EP", Arial, Helvetica, "Microsoft Yahei", "微软雅黑","STHeiti","华文黑体", sans-serif;
}

.font-en {
  font-family: "CS-EP", Arial, Helvetica, sans-serif;
}

.font-cs-ep {
  font-family: "CS-EP" !important;
}

.font-cs-et {
  font-family: "CS-ET" !important;
}

.font-cs-headline {
  font-family: "CS-Headline", "CS-EP", Arial, Helvetica, sans-serif !important;
}

.font-yahei {
  font-family: "微软雅黑" !important;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
