$screen-s: 900px;
$cs-white: #fff;
$cs-black: #000;
$cs-grey-05: #7c7c7b;
$w-full: 100%;
$w-flex: 80%;
$size-s: 13px;
$size-m: 16px;
$size-l: 36px;

.modal-login {
    background-color: rgba(0, 0, 0, 0.5);
    height: 100vh;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;

    & > .modal-box {
        height: 95%;
        overflow-y: scroll;
        max-width: 1120px;
        width: 100%;
        background-color: $cs-white;
        z-index: 99;
        // margin: 12px 0 0 0;
        padding: 60px 160px;
        // padding: 160px 244px;
        box-sizing: border-box;

        @media only screen and (max-width: $screen-s) {
            padding: 20px;
            max-width: 90%;
            height: 90%;
        }
        
        & > .header {
            width: $w-full;
            display: flex;
            justify-content: space-between;
            align-items: flex-end;

            & > .lang-container {
                width: $w-full;
                display: flex;
                justify-content: flex-end;
                align-items: center;

                & > .lang {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    font-size: 13px;
                    font-weight: 700;
                    width: 34px;
                    height: 34px;
                    cursor: pointer;
                }

                & > .lang-active {
                    border-bottom: 2px solid $cs-black;
                }
            }
        }

        &>.main-content {
            text-align: left;
            margin: 72px 0 0 0;

            &>.title {
                font-weight: 400;
                font-size: 40px;
            }
            
            &>.content {
                margin: 44px 0 0 0;
                font-weight: 400;
                font-size: 14px;

                &>.item {
                    margin: 60px 0 0 0;
                }
            }
        }

        &>.action {
            margin: 138px 0 0 0;
            width: $w-full;
            display: flex;
            justify-content: flex-end;
            align-items: center;

            @media only screen and (max-width: $screen-s) {
                margin: 138px 0 50px 0;
            }

            &>.btn-container {
                display: flex;
                justify-content: center;
                align-items: center;

                &>.btn {
                    margin: 0 0 0 20px;
                    font-size: 16px;
                    font-weight: 700;
                    padding: 10px 32.5px;
                    box-sizing: border-box;
                    border: 1.5px solid $cs-black;

                    &:hover {
                        cursor: pointer;
                    }
                }

                &>.btn-accept {
                    color: $cs-white;
                    background-color: $cs-black;
                }
                
                &>.btn-decline {
                    color: $cs-black;
                    background-color: $cs-white;
                }
            }
        }
    }
}
