$screen-s: 900px;
$cs-white: #fff;
$cs-black: #000;
$cs-grey-05: #7c7c7b;
$w-full: 100%;
$w-flex: 80%;
$size-s: 13px;
$size-m: 16px;
$size-l: 36px;

.user-header {
    z-index: 999;
    width: $w-flex;
    height: 121px;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    background-color: $cs-white;
    padding: 34px 0 0 0;
    box-sizing: border-box;

    @media only screen and (max-width: $screen-s) {
        flex-direction: column;
        align-items: flex-start;
        min-height: 300px;
    }

    & > .logo {
        width: 200px;
        padding: 0 0 22px 0;
        box-sizing: border-box;

        @media only screen and (max-width: $screen-s) {
            min-height: 50px;
        }

        & > img {
            width: 200px;
        }
    }

    & > .title {
        flex: 1 0 auto;
        font-size: 24px;
        margin: 0 0 0 17px;
        padding: 0 0 22px 0;
        box-sizing: border-box;
        font-family: "CS-EP", Arial, Helvetica, sans-serif;

        @media only screen and (max-width: $screen-s) {
            margin: 33px 0 0 0;
        }
    }

    & > .nav-container {
        width: $w-full;
        display: flex;
        justify-content: flex-start;
        align-items: flex-end;
        margin: 0 0 0 40px;

        @media only screen and (max-width: $screen-s) {
            margin: 0;
        }

        & > .nav-item {
            // width: $w-full;
            padding: 22px 21px;
            box-sizing: border-box;
            font-size: 13px;
            font-weight: 700;
            text-decoration: none;
            color: $cs-black;

            @media only screen and (max-width: $screen-s) {
                width: 100%;
                text-align: center;
            }

            &:hover {
                cursor: pointer;
            }
        }

        & > .active {
            border-bottom: 2px solid $cs-black;
        }
    }

    & > .right-content {
        display: flex;
        justify-content: flex-end;
        align-items: center;

        @media only screen and (max-width: $screen-s) {
            width: 100%;
            flex-direction: row-reverse;
            justify-content: space-between;
        }

        & > .logout-container {
            display: flex;
            justify-content: flex-end;
            align-items: center;
            padding: 0 0 22px 0;
            box-sizing: border-box;

            @media only screen and (max-width: $screen-s) {
                margin: 20px 0 0 0;
                width: 100%;
                justify-content: flex-end;
                align-items: flex-end;
            }

            & > .btn-logout {
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 13px;
                font-weight: 700;

                width: 90px;
                height: 34px;

                & > img {
                    margin: 0 10px 0 0;
                    width: 24px;
                    height: 24px;
                }

                &:hover {
                    cursor: pointer;
                }
            }
        }

        & > .lang-container {
            // width: $w-full;
            display: flex;
            justify-content: flex-end;
            align-items: center;
            padding: 0 0 22px 0;
            box-sizing: border-box;

            @media only screen and (max-width: $screen-s) {
                margin: 40px 0 0 0;
                justify-content: flex-start;
            }

            & > .lang {
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 13px;
                font-weight: 700;
                width: 34px;
                height: 34px;
                cursor: pointer;
            }

            & > .lang-active {
                border-bottom: 2px solid $cs-black;
            }
        }
    }
}

.post-header {
    width: 80%;
    justify-content: flex-start;
    align-items: center;

    & > .nav-container {
        width: $w-full;
        display: flex;
        justify-content: flex-start;
        align-items: flex-end;
        // margin: 0 0 0 40px;
        margin: 0;

        @media only screen and (max-width: $screen-s) {
            margin: 0;
        }

        & > .nav-item {
            // width: $w-full;
            padding: 22px 21px;
            box-sizing: border-box;
            font-size: 13px;
            font-weight: 700;
            text-decoration: none;
            color: $cs-black;

            @media only screen and (max-width: $screen-s) {
                width: 100%;
                text-align: center;
            }

            &:hover {
                cursor: pointer;
            }
        }

        & > .active {
            border-bottom: 2px solid $cs-black;
        }
    }
}
