$screen-s: 900px;
$cs-white: #fff;
$cs-black: #000;
$cs-grey-05: #7c7c7b;
$w-full: 100%;
$w-flex: 80%;
$size-s: 13px;
$size-m: 16px;
$size-l: 36px;

html {
    background-color: $cs-white;
}

.login-container {
    width: $w-full;
    display: flex;
    // height: 100vh;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    background-color: $cs-white;

    & > .pre-header {
        width: $w-full;
        height: 45px;
        background-color: $cs-black;
    }

    & > .header {
        width: $w-flex;
        height: 99px;
        // margin-bottom: 20px;
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        background-color: $cs-white;
        padding: 34px 0 0 0;
        box-sizing: border-box;

        @media only screen and (max-width: $screen-s) {
            flex-direction: column;
            align-items: flex-start;
            min-height: 200px;
        }

        & > .logo {
            width: 200px;

            @media only screen and (max-width: $screen-s) {
                min-height: 50px;
            }

            & > img {
                // width: $w-full;
                width: 200px;
            }
        }

        & > .title {
            flex: 1 0 auto;
            font-size: 24px;
            margin: 0 0 0 17px;
            font-family: "CS-EP", Arial, Helvetica, sans-serif;

            @media only screen and (max-width: $screen-s) {
                text-align: left;
                margin: 33px 0 0 0;
            }
        }

        & > .lang-container {
            width: $w-full;
            display: flex;
            justify-content: flex-end;
            align-items: center;

            @media only screen and (max-width: $screen-s) {
                margin: 40px 0 0 0;
                justify-content: flex-start;
            }

            & > .lang {
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 13px;
                font-weight: 700;
                width: 34px;
                height: 34px;
                cursor: pointer;
            }

            & > .lang-active {
                border-bottom: 2px solid $cs-black;
            }
        }
    }

    & > .main-content {
        margin: 95px 0 0 0;
        width: $w-flex;
        // flex: 1 0 auto;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        @media only screen and (max-width: $screen-s) {
            margin: 150px 0 0 0;
        }

        & > .title {
            width: $w-full;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            font-size: $size-l;
            font-weight: 400;

            @media only screen and (max-width: $screen-s) {
                text-align: left;
            }
        }

        & > .important-note {
            margin: 75px 0 0 0;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
            padding: 15px 15px 15px 10px;
            box-sizing: border-box;

            width: 450px;
            background-color: #f1f2f2;

            @media only screen and (max-width: $screen-s) {
                width: 100%;
            }

            & > .title,
            & > .content {
                text-align: left;
            }

            & > .title {
                font-weight: 700;
                font-size: $size-s;
            }
            
            & > .content {
                font-size: $size-s;
                margin: 15px 0 0 0;
                color: $cs-grey-05;
            }
        }

        & > .form-box {
            width: 450px;
            margin: 60px 0 0 0;

            @media only screen and (max-width: $screen-s) {
                width: 100%;
            }

            & > .title {
                width: $w-full;
                text-align: left;
                padding: 15px 15px 15px 10px;
                box-sizing: border-box;

                font-weight: 700;
                font-size: $size-m;
            }

            & > .input-sn {
                width: $w-full;

                & > input {
                    margin: 5px 0 0 0;
                    width: $w-full;
                    height: 40px;
                    border: none;
                    border-bottom: 1px solid $cs-black;
                    padding: 10px;
                    box-sizing: border-box;
                    font-size: 20px;
                    font-weight: 400;

                    &:focus {
                        outline: none;
                    }
                }
            }

            & > .btn-submit-container {
                margin: 60px 0 0 0;
                width: $w-full;
                display: flex;
                justify-content: flex-end;
                align-items: center;

                & > .btn {
                    width: 120px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    background-color: $cs-black;
                    color: $cs-white;
                    padding: 13px 25px;
                    box-sizing: border-box;

                    @media only screen and (max-width: $screen-s) {
                        width: 100%;
                    }

                    &:hover {
                        cursor: pointer;
                    }

                    & > .text {
                        font-size: 16px;
                        font-weight: 700;
                    }

                    & > .icon {
                        margin: 0 0 0 10px;
                        width: 16px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        & > img {
                            width: 100%;
                        }
                    }
                }
            }
        }
    }

    & > .footer {
        width: $w-full;
        margin: 75px 0 0 0;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 143px;
        background-color: $cs-black;
        color: $cs-white;

        & > .footer-content {
            width: $w-flex;
            display: flex;
            justify-content: space-between;
            align-items: center;

            @media only screen and (max-width: $screen-s) {
                flex-direction: column-reverse;
            }

            & > .logo {
                width: 215px;
                & > img {
                    width: 215px;
                }
            }

            & > .url {
                @media only screen and (max-width: $screen-s) {
                    margin: 25px 0 0 0;
                }
            }
        }
    }

    & > .post-footer {
        width: $w-full;
        height: 52px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: $cs-white;
        & > .content {
            width: $w-flex;
            display: flex;
            justify-content: flex-end;
            align-items: center;

            @media only screen and (max-width: $screen-s) {
                justify-content: flex-start;
            }

            & > .text {
                font-size: $size-m;
            }
        }
    }
}
