$screen-s: 900px;
$cs-white: #fff;
$cs-black: #000;
$cs-grey-03: #dadada;
$cs-grey-04: #a8a8a7;
$cs-grey-05: #7c7c7b;
$w-full: 100%;
$w-flex: 80%;
$size-s: 13px;
$size-m: 16px;
$size-l: 36px;

.navigate:hover {
    cursor: pointer;
}

.news-story-container {
    width: $w-full;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    text-align: left;

    & > .main-content {
        width: $w-flex;
        margin: 50px 0 0 0;

        @media only screen and (max-width: $screen-s) {
            // margin: 200px 0 0 0;
        }

        & > .pre-title {
            display: flex;
            justify-content: space-between;
            align-items: center;

            @media only screen and (max-width: $screen-s) {
                flex-direction: column;
                align-items: flex-start;
            }

            &>.content-left{
                font-size: 16px;
                font-weight: 700;

                &:hover{
                    cursor: pointer;
                }
            }

            &>.content-right{
                color: $cs-grey-04;
                font-size: 14px;
                font-weight: 400;
                max-width: 60%;
                text-align: right;

                @media only screen and (max-width: $screen-s) {
                    margin: 45px 0 0 0;
                }
            }
            
            & > .content {
                display: flex;
                justify-content: center;
                align-items: center;

                & > img {
                    height: 24px;
                    width: 24px;
                }
                & > .icon-info {
                    & > img {
                        margin: 0 0 0 20px;
                        height: 24px;
                        width: 24px;
                    }
                }
            }
        }

        &>.title {
            margin: 65px 0 0 0;
            font-size: 56px;
            font-weight: 400;
        }
        
        &>.datetime {
            margin: 40px 0 0 0;
            font-size: 13px;
            font-weight: 400;
            color: #757575;
        }
        
        &>.body {
            margin: 40px 0 0 0;
            font-size: 16px;
            font-weight: 400;
        }
    }
}
