$screen-s: 900px;
$cs-white: #fff;
$cs-black: #000;
$cs-grey-03: #dadada;
$cs-grey-04: #a8a8a7;
$cs-grey-05: #7c7c7b;
$w-full: 100%;
$w-flex: 80%;
$size-s: 13px;
$size-m: 16px;
$size-l: 36px;

.navigate:hover {
    cursor: pointer;
}

.news-container {
    width: $w-full;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    text-align: left;

    & > .main-content {
        width: $w-flex;
        // height: 300px;
        // background-color: grey;

        @media only screen and (max-width: $screen-s) {
            // margin: 150px 0 0 0;
        }

        & > .title {
            margin: 68px 0 0 0;
            font-size: 56px;
            font-weight: 400;
        }

        & > .sub-title {
            margin: 45px 0 0 0;
            font-size: 24px;
            font-weight: 400;
        }

        & > .filter-container {
            margin: 40px 0 0 0;

            & > .title {
                font-size: 16px;
                font-weight: 700;
            }

            & > .input-container {
                margin: 26px 0 0 0;
                display: flex;
                justify-content: space-between;
                align-items: center;

                @media only screen and (max-width: $screen-s) {
                    flex-direction: column;
                }

                & > .input_select {
                    margin: 0 30px 0 0;
                    padding: 10px 14px;
                    box-sizing: border-box;
                    color: #696968;
                    font-size: 20px;
                    min-width: 250px;
                    border: none;
                    border-bottom: 1px solid $cs-black;

                    @media only screen and (max-width: $screen-s) {
                        width: 100%;
                    }

                    &:hover {
                        cursor: pointer;
                    }

                    &:focus {
                        outline: none;
                    }

                    & > option {
                        padding: 10px 150px 10px 14px;
                        font-size: 20px;
                        color: $cs-black;
                        border-radius: none;
                    }
                }

                & > .input_text {
                    margin: 0 30px 0 0;
                    padding: 10px 14px;
                    box-sizing: border-box;
                    color: #696968;
                    font-size: 20px;
                    min-width: 250px;
                    border: none;
                    border-bottom: 1px solid $cs-black;

                    @media only screen and (max-width: $screen-s) {
                        width: 100%;
                    }

                    &:focus {
                        outline: none;
                    }
                }

                & > .select {
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-start;
                    align-items: flex-start;
                    margin: 0 10px 0 0;
                    width: 30%;
                    position: relative;
                    border-bottom: 1px solid $cs-black;

                    @media only screen and (max-width: $screen-s) {
                        width: 100%;
                        margin: 0;
                    }

                    & > .selected-item {
                        width: $w-full;
                        min-width: 250px;
                        display: flex;
                        justify-content: space-between;
                        align-items: center;

                        font-size: 20px;
                        font-weight: 400;
                        // padding: 10px 150px 10px 10px;
                        padding: 10px;
                        box-sizing: border-box;
                        cursor: pointer;

                        & > div {
                            & > img {
                                height: 24px;
                                width: 24px;
                            }
                        }
                    }

                    & > .option-container-active {
                        display: block !important;
                    }
                    & > .option-container {
                        z-index: 99;
                        border: 1px solid $cs-black;
                        background-color: $cs-white;
                        position: absolute;
                        display: none;
                        width: 100%;
                        cursor: pointer;
                        overflow-x: hidden;
                        overflow-y: scroll;
                        max-height: 200px;
                        &::-webkit-scrollbar {
                            width: 10px;
                        }

                        &::-webkit-scrollbar-track {
                            background: $cs-white;
                        }

                        &::-webkit-scrollbar-thumb {
                            background-color: $cs-black;
                            border: 3px solid $cs-black;
                        }

                        & > .option-item {
                            width: $w-full;
                            min-width: 250px;

                            font-size: 20px;
                            font-weight: 400;
                            // padding: 10px 130px 10px 10px;
                            padding: 10px;
                            box-sizing: border-box;

                            &:hover {
                                background-color: #e6e7e8;
                            }

                            &:active {
                                color: $cs-white;
                                background-color: $cs-black;
                            }
                        }
                    }
                }

                & > .input-text-container {
                    // min-width: 310px;
                    width: 30%;

                    padding: 10px 14px;
                    box-sizing: border-box;
                    border-bottom: 1px solid $cs-black;
                    position: relative;

                    @media only screen and (max-width: $screen-s) {
                        width: 100%;
                        padding: 10px;
                    }

                    & > .input_text {
                        min-height: 27px;
                        margin: 0 30px 0 0;
                        color: #696968;
                        font-size: 20px;
                        // min-width: 250px;
                        width: 100%;
                        border: none;

                        &:focus {
                            outline: none;
                        }
                    }

                    & > .stock-search-container-hide {
                        display: none !important;
                    }
                    & > .stock-search-container {
                        position: absolute;
                        background-color: $cs-white;
                        top: 55px;
                        left: 0;
                        border: 1px solid $cs-black;
                        width: 100%;

                        & > div {
                            overflow-x: hidden;
                            overflow-y: scroll;
                            max-height: 200px;
                            &::-webkit-scrollbar {
                                width: 10px;
                            }

                            &::-webkit-scrollbar-track {
                                background: $cs-white;
                            }

                            &::-webkit-scrollbar-thumb {
                                background-color: $cs-black;
                                border: 3px solid $cs-black;
                            }

                            & > div {
                                width: $w-full;
                                min-width: 310px;

                                font-size: 20px;
                                font-weight: 400;
                                // padding: 10px 130px 10px 10px;
                                padding: 10px;
                                box-sizing: border-box;

                                &:hover {
                                    background-color: #e6e7e8;
                                    cursor: pointer;
                                }

                                &:active {
                                    color: $cs-white;
                                    background-color: $cs-black;
                                }
                            }
                        }
                    }

                    & > img {
                        width: 24px;
                        height: 24px;
                        position: absolute;
                        top: 10px;
                        right: 10px;
                    }
                }
            }

            & > .btn-container {
                margin: 60px 0 0 0;
                display: flex;
                justify-content: flex-end;
                align-items: center;

                & > .btn {
                    padding: 12.5px 28px;
                    box-sizing: border-box;

                    @media only screen and (max-width: $screen-s) {
                        text-align: center;
                        width: 100%;
                    }
                }

                & > .btn-submit,
                & > .btn-reset {
                    margin: 0 0 0 10px;

                    color: $cs-white;
                    background-color: $cs-black;
                    font-size: 16px;
                    font-weight: 700;

                    &:hover {
                        cursor: pointer;
                    }
                }
            }
        }

        & > .table-info {
            margin: 85px 0 0 0;
            display: flex;
            justify-content: space-between;
            align-items: center;

            @media only screen and (max-width: $screen-s) {
                margin: 40px 0 0 0;
                flex-direction: column-reverse;
            }

            & > .content-left {
                display: flex;
                justify-content: flex-start;
                align-items: center;

                @media only screen and (max-width: $screen-s) {
                    margin: 40px 0 0 0;
                    flex-direction: column;
                    text-align: left;
                    width: 100%;
                }

                & > .title {
                    font-size: 24px;
                    font-weight: 400;

                    @media only screen and (max-width: $screen-s) {
                        width: 100%;
                    }
                }

                & > .content {
                    margin: 0 0 0 15px;
                    font-size: 13px;
                    font-weight: 400;

                    @media only screen and (max-width: $screen-s) {
                        width: 100%;
                        margin: 15px 0 0 0;
                    }
                }
            }

            & > .content-right {
                display: flex;
                justify-content: center;
                align-items: center;
                max-width: 60%;
                text-align: right;

                @media only screen and (max-width: $screen-s) {
                    flex-direction: row-reverse;
                    max-width: 100%;
                }

                & > .text {
                    margin: 0 20px 0 0;
                    color: $cs-grey-04;
                    font-size: 14px;
                    font-size: 400;
                    text-align: right;

                    @media only screen and (max-width: $screen-s) {
                        text-align: left;
                        margin: 0 0 0 10px;
                    }
                }

                & > .icon-info {
                    & > img {
                        height: 24px;
                        width: 24px;
                    }
                }
            }
        }

        & > .table-loading-info {
            margin: 10px 0;
            font-size: 50px;
        }

        & > .table-content {
            margin: 40px 0 0 0;

            & > table {
                width: $w-full;
                border: 0;
                border-spacing: 0 5px;

                & > thead {
                    & > tr {
                        & > td {
                            border-bottom: 1px solid $cs-black;
                            padding: 15px 0;
                            box-sizing: border-box;
                            font-size: 16px;
                            font-weight: 700;
                        }
                    }
                }

                & > tbody {
                    & > tr {
                        &:hover {
                            cursor: pointer;
                        }

                        & > td {
                            border-bottom: 1px solid $cs-grey-03;
                            vertical-align: center;
                            font-size: 16px;
                            font-weight: 400;

                            & > img {
                                width: 24px;
                                height: 24px;
                            }
                        }

                        & > .td-text {
                            padding: 15px 15px 15px 0;
                            box-sizing: border-box;
                        }

                        & > .td-img {
                            padding: 15px;
                            box-sizing: border-box;
                            text-align: right;
                        }
                    }
                }
            }
        }

        & > .table-pagination {
            margin: 58px 0 0 0;
            display: flex;
            justify-content: flex-end;
            align-items: center;

            @media only screen and (max-width: $screen-s) {
                width: 100%;
            }

            & > .paging-container {
                display: flex;
                justify-content: center;
                align-items: center;

                @media only screen and (max-width: $screen-s) {
                    width: 100%;
                }

                & > .page-prev,
                & > .page-next {
                    &:hover {
                        cursor: pointer;
                    }

                    & > img {
                        width: 24px;
                        height: 24px;
                    }
                }

                & > .page-prev {
                    margin: 0 38px 0 0;
                    @media only screen and (max-width: $screen-s) {
                        margin: 0 20px 0 0;
                    }
                }
                & > .page-next {
                    margin: 0 0 0 38px;
                    @media only screen and (max-width: $screen-s) {
                        margin: 0 0 0 20px;
                    }
                }

                & > .number-row {
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    & > .paging {
                        margin: 0 10px;
                        padding: 25px 15px;
                        box-sizing: border-box;
                        font-size: 16px;
                        font-weight: 400;

                        @media only screen and (max-width: $screen-s) {
                            padding: 20px 10px;
                        }

                        &:hover {
                            cursor: pointer;
                        }
                    }

                    & > .paging-active {
                        border-bottom: 2.5px solid $cs-black;
                    }
                }
            }
        }
    }
}

.td-wrap {
    word-wrap: break-word;
    word-break: break-word;
}
